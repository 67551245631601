import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import AccountContent from "../../components/AccountContent"
import { Link, navigate } from "gatsby"
import "./my-account.scss"
import { isUserLoggedIn } from "../../utils/function"
import Seo from "../../components/seo"

function Dashboard() {
  const auth =isUserLoggedIn()
  const username =auth!==null?auth.user:null
  const seo = {
    title:"My account - Backa Australia",
    description:""
  }
  return (
    <Layout>
      <Seo title={seo.title} description={seo.description}/>
      <div className="section my-account">
        <div className="container pd-row">
        <AccountContent>
          <p>Hello <strong>{username}</strong> (not <strong>{username}</strong>? 
          <Link onClick={() => {
          localStorage.removeItem("auth")
          sessionStorage.removeItem("isLogged")
          }} to="/">Log out</Link>)</p>
          <p>From your account dashboard you can view your{' '} 
            <Link to="/my-account/orders">recent orders</Link>,
            manage your <Link to="/my-account/edit-address">shipping and billing addresses</Link>, 
             and <Link to="/my-account/edit-account">edit your password and account details</Link>.
          </p>
        </AccountContent>
         
        </div>
      </div>
    </Layout>
  )
}

export default Dashboard